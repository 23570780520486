import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import HeroArea from "@containers/hero/layout-01";
import ITSolutionArea from "@containers/it-solution/layout-01";
import DentalTechnologyArea from "@containers/dental-technology/layout-01";
import FunfactArea from "@containers/funfact/layout-01";
import DentalServiceArea from "@containers/dental-service/layout-01";
import TestimonialArea from "@containers/testimonial/layout-01";
import ContactArea from "@containers/contact/layout-01";
import FaqArea from "@containers/faq/layout-02";

const HomepagePage = ({ location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo title="HomePage" />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                }}
            />
            <main className="site-wrapper-reveal">
                <HeroArea data={content["hero-section"]} />
                <DentalTechnologyArea
                    data={{
                        ...content["service-section"],
                        items: data.allDentalTechnology.nodes,
                    }}
                />
                <ITSolutionArea
                    data={{
                        ...content["feature-section"],
                        items: data.allItSolution.nodes,
                    }}
                />
                <DentalServiceArea
                    data={{
                        ...content["dental-service-section"],
                        items: data.allDentalService.nodes,
                    }}
                />
                <FaqArea data={content["faq-section"]} />
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query homepagePageQuery($locale: String!) {
        allGeneral(filter: { locale: { eq: $locale } }) {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(
            title: { eq: "homepage" }
            pageType: { eq: "frontpage" }
            locale: { eq: $locale }
        ) {
            content {
                ...PageContent
            }
        }
        allItSolution(
            sort: { id: DESC }
            filter: { is_featured: { eq: true } , locale: { eq: $locale } }
            limit: 3
        ) {
            nodes {
                ...ItSolutionTwo
            }
        }
        allDentalTechnology(
            sort: { id: DESC }
            filter: { is_featured: { eq: false }, locale: { eq: $locale} }
            limit: 3
        ) {
            nodes {
                ...DentalTechnologyThree
            }
        }
        allDentalService(
            filter: { is_featured: { eq: true }, locale: { eq: $locale } }
            limit: 4
        ) {
            nodes {
                ...DentalServiceOne
            }
        }
       
    }
`;

HomepagePage.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
            }),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allDentalTechnology: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allDentalService: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        featuredBlogs: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        recentBlogs: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default HomepagePage;

import React from "react";
import PropTypes from "prop-types";
// import NavBar, {
//     NavItem,
//     NavLink,
//     Submenu,
//     Megamenu,
//     ExpandButton,
// } from "@ui/navbar";
// import Heading from "@ui/heading";
import { getClosest, getSiblings } from "@utils";
import {
    StyledMobileMenu,
    StyledNavbar,
    StyledNavitem,
    StyledNavlink,
    StyledButton,
    StyledSubmenu,
    StyledMegamenu,
    StyledMegatitle,
} from "./style";
import cn from "clsx";

const MobileMenu = ({ menuData }) => {
    const [activeParentIds, setActiveParentIds] = React.useState([]);

    const toggleMenu = React.useCallback(
        (id, main = true) => {
            if (main) {
                if (activeParentIds.includes(id)) {
                    setActiveParentIds([]);
                } else {
                    setActiveParentIds([id]);
                }
            } else {
                if (activeParentIds.includes(id)) {
                    setActiveParentIds(
                        activeParentIds.filter((item) => item !== id)
                    );
                } else {
                    const [mainId] = activeParentIds;
                    setActiveParentIds([mainId, id]);
                }
            }
        },
        [activeParentIds]
    );

    return (
        <StyledMobileMenu>
            <StyledNavbar>
                {menuData.map((menu, i) => {
                    const submenu = menu.submenu ? menu.submenu : null;
                    const megamenu = menu.megamenu ? menu.megamenu : null;
                    const menuIndex = i;
                    return (
                        <StyledNavitem
                            key={`mainmenu-${menu.id}-${
                                activeParentIds.includes(
                                    `menu-item-${menuIndex}`
                                )
                                    ? "active"
                                    : "inactive"
                            }`}
                            hasSubmenu={submenu}
                            hasMegamenu={megamenu}
                            className={cn(
                                "menu-item",
                                activeParentIds.includes(
                                    `menu-item-${menuIndex}`
                                ) && "submenu-open"
                            )}
                            id={`menu-item-${menuIndex}`}
                        >
                            <StyledNavlink path={menu.link}>
                                {menu.text}
                            </StyledNavlink>
                            {(submenu || megamenu) && (
                                <StyledButton
                                    className="menu-expand"
                                    onClick={(e) =>
                                        toggleMenu(
                                            `menu-item-${menuIndex}`,
                                            true
                                        )
                                    }
                                >
                                    <i className="icon fa fa-angle-down"></i>
                                </StyledButton>
                            )}

                            {submenu && (
                                <StyledSubmenu className="submenu">
                                    {submenu.map((subitem, j) => {
                                        const submenuLevelTwo = subitem.submenu;
                                        const submenuIndex = j;
                                        return (
                                            <StyledNavitem
                                                key={`submenu-${
                                                    menu.id
                                                }-${submenuIndex}-${
                                                    activeParentIds.includes(
                                                        `submenu-item-${menuIndex}${submenuIndex}`
                                                    )
                                                        ? "active"
                                                        : "inactive"
                                                }`}
                                                className={cn(
                                                    "menu-item",
                                                    activeParentIds.includes(
                                                        `submenu-item-${menuIndex}${submenuIndex}`
                                                    ) && "submenu-open"
                                                )}
                                                id={`submenu-item-${menuIndex}${submenuIndex}`}
                                                $inSubmenu={true}
                                            >
                                                <StyledNavlink
                                                    path={subitem.link}
                                                    $inSubmenu={true}
                                                >
                                                    {subitem.text}
                                                </StyledNavlink>
                                                {submenuLevelTwo && (
                                                    <StyledButton
                                                        className="menu-expand"
                                                        $inSubmenu={true}
                                                        onClick={(e) =>
                                                            toggleMenu(
                                                                `submenu-item-${menuIndex}${submenuIndex}`,
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <i className="icon fa fa-angle-down"></i>
                                                    </StyledButton>
                                                )}
                                                {submenuLevelTwo && (
                                                    <StyledSubmenu>
                                                        {submenuLevelTwo.map(
                                                            (
                                                                subitemLevelTwo,
                                                                k
                                                            ) => {
                                                                const subsubmenuIndex =
                                                                    k;
                                                                return (
                                                                    <StyledNavitem
                                                                        key={`submenu-${
                                                                            menu.id
                                                                        }-${submenuIndex}-${subsubmenuIndex}-${
                                                                            activeParentIds.includes(
                                                                                `submenu-item-${menuIndex}${submenuIndex}${subsubmenuIndex}`
                                                                            )
                                                                                ? "active"
                                                                                : "inactive"
                                                                        }`}
                                                                        className={cn(
                                                                            "menu-item",
                                                                            activeParentIds.includes(
                                                                                `submenu-item-${menuIndex}${submenuIndex}${subsubmenuIndex}`
                                                                            ) &&
                                                                                "submenu-open"
                                                                        )}
                                                                        id={`submenu-item-${menuIndex}${submenuIndex}${subsubmenuIndex}`}
                                                                    >
                                                                        <StyledNavlink
                                                                            path={
                                                                                subitemLevelTwo.link
                                                                            }
                                                                        >
                                                                            {
                                                                                subitemLevelTwo.text
                                                                            }
                                                                        </StyledNavlink>
                                                                    </StyledNavitem>
                                                                );
                                                            }
                                                        )}
                                                    </StyledSubmenu>
                                                )}
                                            </StyledNavitem>
                                        );
                                    })}
                                </StyledSubmenu>
                            )}
                            {megamenu && (
                                <StyledMegamenu className="megamenu">
                                    {megamenu.map((megaitem, i) => {
                                        const megaSubmenu = megaitem.submenu;
                                        const megaIndex = i;
                                        return (
                                            <StyledNavitem
                                                key={`megamenu-${
                                                    menu.id
                                                }-${megaIndex}-${
                                                    activeParentIds.includes(
                                                        `megamenu-${menu.id}-${megaIndex}`
                                                    )
                                                        ? "active"
                                                        : "inactive"
                                                }`}
                                                id={`megamenu-${menu.id}-${megaIndex}`}
                                                className={cn(
                                                    activeParentIds.includes(
                                                        `megamenu-${menu.id}-${megaIndex}`
                                                    ) && "submenu-open"
                                                )}
                                                $inSubmenu={true}
                                            >
                                                <StyledMegatitle>
                                                    <span>
                                                        {megaitem.title}
                                                    </span>
                                                    <StyledButton
                                                        className="menu-expand"
                                                        $inSubmenu={true}
                                                        onClick={(e) =>
                                                            toggleMenu(
                                                                `megamenu-${menu.id}-${megaIndex}`,
                                                                false
                                                            )
                                                        }
                                                    >
                                                        <i className="icon fa fa-angle-down"></i>
                                                    </StyledButton>
                                                </StyledMegatitle>
                                                {megaSubmenu && (
                                                    <StyledSubmenu className="submenu">
                                                        {megaSubmenu.map(
                                                            (
                                                                megaSubitem,
                                                                i
                                                            ) => {
                                                                return (
                                                                    <StyledNavitem
                                                                        key={`megasubmenu-${megaIndex}-${i}`}
                                                                        $inSubmenu={
                                                                            true
                                                                        }
                                                                    >
                                                                        <StyledNavlink
                                                                            $inSubmenu={
                                                                                true
                                                                            }
                                                                            path={
                                                                                megaSubitem.link
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {
                                                                                    megaSubitem.text
                                                                                }
                                                                            </span>
                                                                        </StyledNavlink>
                                                                    </StyledNavitem>
                                                                );
                                                            }
                                                        )}
                                                    </StyledSubmenu>
                                                )}
                                            </StyledNavitem>
                                        );
                                    })}
                                </StyledMegamenu>
                            )}
                        </StyledNavitem>
                    );
                })}
            </StyledNavbar>
        </StyledMobileMenu>
    );
};

MobileMenu.propTypes = {
    menuData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default MobileMenu;

const headerData = {
    menu: [
        {
            id: "menu-1",
            text: "Home",
            link: "/",
            submenu: [
                {
                    id: "menu-12",
                    text: "Appointment",
                    link: "/homepages/appointment",
                },
            ],
        },
        {
            id: "menu-2",
            text: "About us",
            link: "/about-us",
            submenu: [
                {
                    id: "menu-22",
                    text: "Contact Us",
                    link: "/contact-us",
                },
                {
                    id: "menu-23",
                    text: "Our Staff",
                    link: "/staff",
                },
                {
                    id: "menu-24",
                    text: "Why Albania",
                    link: "/why-albania",
                },
                {
                    id: "menu-25",
                    text: "FAQs",
                    link: "/faq",
                }
            ],
        },
        {
            id: "menu-2",
            text: "About us",
            link: "/about-us",
            submenu: [
                {
                    id: "menu-22",
                    text: "Contact Us",
                    link: "/contact-us",
                },
                {
                    id: "menu-23",
                    text: "Our Staff",
                    link: "/staff",
                },
                {
                    id: "menu-24",
                    text: "Why Albania",
                    link: "/why-albania",
                },
                {
                    id: "menu-25",
                    text: "FAQs",
                    link: "/faq",
                }
            ],
        },
        {
            id: "menu-6",
            text: "Dental Tourism",
            link: "/dental-tourism",
            submenu: [
                {
                    id: "menu-31",
                    text: "Why Albania",
                    link: "/why-albania/"
                },
                {
                    id: "menu-32",
                    text: "How it works?",
                    link: "/dental-tourism/"
                }
            ]
        },
        {
            id: "menu-6",
            text: "Contact us",
            link: "/contact-us"
        },
    ],
};

export default headerData;

import { LocaleContext } from "gatsby-theme-i18n";
import { useContext } from "react";
import translations from "@constants/translations";

const useTranslation = () => {
    const locale = useContext(LocaleContext);

    const t = (str) => {
        const fallback = "en";
        let value = translations[locale]
            ? translations[locale][str]
                ? translations[locale][str]
                : translations[fallback][str]
            : translations[fallback][str];

        if (value === undefined) {
            value = str;
        }

        return value;
    };

    return { t, locale };
};

export default useTranslation;

const translations = {
    en: {
        Search: "Search",
        ExploreAlbania: "Explore the Hidden Gem: Why Visit Albania?",
        GetStarted: "Get Started",
        Services: "Services",
        Staff: "Staff",
        Location: "Location",
        DentalTourism: "Dental Tourism",
        Contact_Us: "Contact Us",
        Describe: "Ju lutem shkruani problemin tuaj",
        Name: "Name",
        Email: "Email",
        Subject: "Subject",
        SendMessage : "SendMessage",
        Touch : "Ne do tju kontaktojme direkt ne emailin e shenuar",
        Book_appointment : "Rezervoni nje takim me njerin nga specialistet",
        Contact_Us: "Na kontaktoni",
        Where_to_Find_Us: "Ku te na gjeni",
        Appointment: "Lini nje takim",
        Quick_links: "Quick Links",
        Our_Staff: "Stafi jone",
        FAQS: "Pyetjet me te shpeshta",
        About_Us: "Rreth Nesh",
        Menu: "Menuja",
        Support: "Suporti",
        Information: "Na telefononi per me shume informacion",
        View_service: "Shikoni Sherbimet"
    },
    it: {
        Search: "Ricerca",
        ExploreAlbania: "Esplora il gioiello nascosto: Perché visitare l'Albania?",
        GetStarted: "Loslegen",
        Services: "Servizi",
        Staff: "Staff",
        Location: "Location",
        DentalTourism: "Turizmi Dentar",
        Contact_Us: "Contact Us",
        Describe: "Ju lutem shkruani problemin tuaj",
        Name: "Name",
        Email: "Email",
        Subject: "Subject",
        SendMessage : "SendMessage",
        Touch : "Ne do tju kontaktojme direkt ne emailin e shenuar",
        Book_appointment : "Rezervoni nje takim me njerin nga specialistet",
        Contact_Us: "Na kontaktoni",
        Where_to_Find_Us: "Ku te na gjeni",
        Appointment: "Lini nje takim",
        Quick_links: "Quick Links",
        Our_Staff: "Stafi jone",
        FAQS: "Pyetjet me te shpeshta",
        About_Us: "Rreth Nesh",
        Menu: "Menuja",
        Support: "Suporti",
        Information: "Na telefononi per me shume informacion",
        View_service: "Shikoni Sherbimet"
    },
    de: {
        Search: "Suche",
        ExploreAlbania: "Entdecke das verborgene Juwel: Warum Albanien besuchen?",
        GetStarted: "Loslegen",
        Services: "Dienstleistungen",
        Staff: "Staff",
        Location: "Location",
        DentalTourism: "Turizmi Dentar",
        Contact_Us: "Contact Us",
        Describe: "Ju lutem shkruani problemin tuaj",
        Name: "Name",
        Email: "Email",
        Subject: "Subject",
        SendMessage : "SendMessage",
        Touch : "Ne do tju kontaktojme direkt ne emailin e shenuar",
        Book_appointment : "Rezervoni nje takim me njerin nga specialistet",
        Contact_Us: "Na kontaktoni",
        Where_to_Find_Us: "Ku te na gjeni",
        Appointment: "Lini nje takim",
        Quick_links: "Quick Links",
        Our_Staff: "Stafi jone",
        FAQS: "Pyetjet me te shpeshta",
        About_Us: "Rreth Nesh",
        Menu: "Menuja",
        Support: "Suporti",
        Information: "Na telefononi per me shume informacion",
        View_service: "Shikoni Sherbimet"
    },
    sq: {
        Search: "Kërko",
        ExploreAlbania: "Eksploro gurin e cmuar: Pse të vizitosh Shqipërinë?",
        GetStarted: "Fillo",
        Services: "Shërbimet",
        Staff: "Stafi",
        Location: "Harta",
        DentalTourism: "Turizmi Dentar",
        Contact_Us: "Na kontaktoni",
        Describe: "Ju lutem shkruani problemin tuaj...",
        Name: "Emri",
        Email: "Emaili",
        Subject: "Subjekti",
        SendMessage : "Dergo mesazhin",
        Touch : "Ne do tju kontaktojme direkt ne emailin e shenuar",
        Book_appointment : "Rezervoni nje takim me njerin nga specialistet",
        Contact_Us: "Na kontaktoni",
        Where_to_Find_Us: "Ku te na gjeni",
        Appointment: "Lini nje takim",
        Quick_links: "Linqe te shpejta",
        Our_Staff: "Stafi jone",
        FAQS: "Pyetjet me te shpeshta",
        About_Us: "Rreth Nesh",
        Menu: "Menuja",
        Support: "Suporti",
        Information: "Na telefononi per me shume informacion",
        View_service: "Shikoni Sherbimet"
    },
};

export default translations;

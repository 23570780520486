import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import cn from "clsx";
import Dropdown from "@ui/dropdown";
import DropdownItem from "@ui/dropdown/dropdown-item";
import DropdownItemInner from "@ui/dropdown/dropdown-item-inner";
import DropdownSubMenu from "@ui/dropdown/dropdown-submenu";
import enFlag from "@assets/images/flags/en.png";
import frFlag from "@assets/images/flags/fr.png";
import itFlag from "@assets/images/flags/it.png";
import sqFlag from "@assets/images/flags/sq.png";
import deFlag from "@assets/images/flags/de.png";
import { LanguageWrap } from "./style";
import {
    LocaleContext,
    LocalizedLink,
    useLocalization,
} from "gatsby-theme-i18n";
import { useLocation } from "@reach/router";

const Language = ({ className, ...props }) => {
    const { config } = useLocalization();
    const { pathname } = useLocation();
    const flagMap = {
        en: enFlag,
        fr: frFlag,
        de: deFlag,
        sq: sqFlag,
        it: itFlag,
    };

    const pathnameWithoutLang = useMemo(() => {
        const [, lang, ...rest] = pathname.split("/");
        let final = "";
        if (config.some((c) => c.code === lang)) {
            final = rest.join("/");
        } else {
            final = pathname;
        }

        if (final.startsWith("/")) {
            return final;
        }

        return `/${final}`;
    }, [pathname]);

    const locale = useContext(LocaleContext);

    const [language, setLanguage] = useState(
        config.map((c) => {
            return {
                ...c,
                isActive: false,
                flag: flagMap[c.code] ?? enFlag,
                id: c.code,
            };
        })
    );

    React.useEffect(() => {
        const activeLang = language.find((lang) => lang.code === locale);
        const copiedState = [...language];
        const languageArr = copiedState.map((lang) => {
            if (lang.code !== activeLang.code) {
                return {
                    ...lang,
                    isActive: false,
                };
            }
            return {
                ...lang,
                isActive: true,
            };
        });
        setLanguage(languageArr);
    }, [locale]);

    let activeLanguage = language.filter((el) => {
        return el.isActive;
    });

    let inActiveLanguage = language.filter((el) => {
        return !el.isActive;
    });

    const onClickHandler = (id) => {
        const copiedState = [...language];
        const languageArr = copiedState.map((lang) => {
            if (lang.id !== id) {
                return {
                    ...lang,
                    isActive: false,
                };
            }
            return {
                ...lang,
                isActive: true,
            };
        });
        setLanguage(languageArr);
    };

    if (!activeLanguage.length) {
        return null;
    }

    return (
        <LanguageWrap className={cn(className, "lang-switcher")} {...props}>
            <Dropdown>
                <DropdownItem active>
                    <DropdownItemInner active>
                        <img
                            src={activeLanguage[0].flag}
                            alt={activeLanguage[0].name}
                        />
                        <span>{activeLanguage[0].name}</span>
                    </DropdownItemInner>
                    <DropdownSubMenu>
                        {inActiveLanguage.map((lang) => {
                            const { id, name, flag } = lang;
                            return (
                                <DropdownItem
                                    key={id}
                                    onClick={() => onClickHandler(id)}
                                >
                                    <LocalizedLink
                                        style={{ width: "100%" }}
                                        to={pathnameWithoutLang}
                                        language={lang.code}
                                    >
                                        <DropdownItemInner>
                                            <img src={flag} alt={name} />
                                            <span>{name}</span>
                                        </DropdownItemInner>
                                    </LocalizedLink>
                                </DropdownItem>
                            );
                        })}
                    </DropdownSubMenu>
                </DropdownItem>
            </Dropdown>
        </LanguageWrap>
    );
};

Language.propTypes = {
    className: PropTypes.string,
};

export default Language;

import { breakpoints } from "./index";

export const theme = {
    colors: {
        primary: "#086AD8",
        text: "#696969",
        heading: "#333333",
        border: "#ededed",
        borderTwo: "#f8f8f8",
        borderDash: "#dddddd",
        white: "#ffffff",
        black: "#000000",
        secondary: "#d2a98e",
        success: "#38cb89",
        info: "#17a2b8",
        warning: "#ffc107",
        danger: "#dc3545",
        light: "#f8f9fa",
        background: "#2e2e2e",
        gray: {
            100: "#F8F8F8",
            200: "#F6FAFE",
            300: "#d6d6d6",
            400: "#eeeeee",
            500: "#fafafa",
            600: "#f5f7fd",
            700: "#7e7e7e",
        },
        silver: "#ababab",
        silverTwo: "#cccccc",
        kimberly: "#6d70a6",
        spring: "#F6F2ED",
        pattens: "#def0ff",
        yellow: "#ffb944",
        whiteSmoke: "#f5f5f5",
        klein: "#002fa6",
        water: "#e7edfa",
    },
    lineHieghts: {
        body: 1.74,
        heading: 1.41,
    },
    fonts: {
        body: `'Inter', sans-serif`,
        heading: `'Inter', sans-serif`,
        fontAwesome: "Font Awesome 5 Free",
        fontAwesomeBrand: "Font Awesome 5 Brands",
    },
    fontSizes: {
        body: "15px",
        h1: "56px",
        h2: "48px",
        h3: "40px",
        h4: "34px",
        h5: "24px",
        h6: "18px",
    },
    fontWeights: {
        body: 400,
        heading: 700,
    },
    radii: {
        sm: "3px",
        md: "5px",
        lg: "8px",
        round: "50%",
        pill: "500px",
    },
    shadows: {
        default: "0 0 40px rgba(51, 51, 51, 0.1)",
        sm: "0 0 20px rgba(51, 51, 51, 0.1)",
        md: "0 18px 40px rgba(51, 51, 51, 0.1)",
        lg: "0 0 40px 5px rgba(51, 51, 51, 0.1)",
        xl: "0 41px 43px rgba(51, 51, 51, 0.07)",
        input: "0 0 0 0.2rem rgb(1 104 250 / 25%)",
    },
    transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
    breakpoints: [...breakpoints],
};

export const darkTheme = {
    colors: {
        primary: "#B08D57", // Adjusted to a gold-like hue
        text: "#E4E4E4", // Lighter text color for contrast against dark backgrounds
        heading: "#FFFFFF", // White for headings to stand out
        border: "#444444", // Darker border color
        borderTwo: "#3B3B3B", // Even darker secondary border
        borderDash: "#333333", // Dark dash border for subtle differentiation
        white: "#202020", // Dark background shades, previously 'background'
        black: "#ffffff", // Inverse of previous, for elements requiring 'black' in dark mode
        secondary: "#B08D57", // Adjusted to a gold-like hue for better visibility in dark mode
        success: "#38cb89", // Keeping success color for contrast
        info: "#17a2b8", // Keeping info color for readability
        warning: "#ffc107", // Keeping warning color for visibility
        danger: "#dc3545", // Keeping danger color for attention
        light: "#404040", // Darker shade for elements considered 'light'
        background: "#1A1A1A", // Dark background for main theme
        gray: {
            100: "#2E2E2E",
            200: "#373737",
            300: "#404040",
            400: "#4A4A4A",
            500: "#535353",
            600: "#5C5C5C",
            700: "#656565",
        },
        silver: "#a9a9a9", // Adjusted to fit dark theme
        silverTwo: "#525252", // Slightly darker than silver
        kimberly: "#5C5F77", // Adjusted to fit dark theme aesthetics
        spring: "#131313", // Darker, muted version to fit the theme
        pattens: "#32424A", // Adjusted to be darker
        yellow: "#ffb944", // Keeping for contrast
        whiteSmoke: "#2E2E2E", // Dark version of whiteSmoke
        klein: "#B08D57", // Adjusted to a gold-like hue, assuming specific use
        water: "#2A3542", // Darkened version of water color
    },
    lineHieghts: {
        body: 1.74,
        heading: 1.41,
    },
    fonts: {
        body: `'Inter', sans-serif`,
        heading: `'Inter', sans-serif`,
        fontAwesome: "Font Awesome 5 Free",
        fontAwesomeBrand: "Font Awesome 5 Brands",
    },
    fontSizes: {
        body: "15px",
        h1: "56px",
        h2: "48px",
        h3: "40px",
        h4: "34px",
        h5: "24px",
        h6: "18px",
    },
    fontWeights: {
        body: 400,
        heading: 700,
    },
    radii: {
        sm: "3px",
        md: "5px",
        lg: "8px",
        round: "50%",
        pill: "500px",
    },
    shadows: {
        default: "0 0 40px rgba(0, 0, 0, 0.2)", // Adjusted for dark theme
        sm: "0 0 20px rgba(0, 0, 0, 0.15)", // Slightly less intense
        md: "0 18px 40px rgba(0, 0, 0, 0.2)", // Maintaining depth
        lg: "0 0 40px 5px rgba(0, 0, 0, 0.2)", // Consistent with theme
        xl: "0 41px 43px rgba(0, 0, 0, 0.1)", // Lower opacity for subtlety
        input: "0 0 0 0.2rem rgb(176 141 87 / 25%)", // Adjusted to match the new gold-like primary color
    },
    transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
    breakpoints: [...breakpoints], // Ensure you define the breakpoints array elsewhere in your code
};

export const creamyDarkTheme = {
    colors: {
        primary: "#FFF9F0",
        text: "#E4E4E4", // Lighter text color for contrast against dark backgrounds
        heading: "#FFFFFF", // White for headings to stand out
        border: "#444444", // Darker border color
        borderTwo: "#3B3B3B", // Even darker secondary border
        borderDash: "#333333", // Dark dash border for subtle differentiation
        white: "#202020", // Dark background shades, previously 'background'
        black: "#ffffff", // Inverse of previous, for elements requiring 'black' in dark mode
        secondary: "#FFF9F0", // Adjusted to a gold-like hue for better visibility in dark mode
        success: "#38cb89", // Keeping success color for contrast
        info: "#17a2b8", // Keeping info color for readability
        warning: "#ffc107", // Keeping warning color for visibility
        danger: "#dc3545", // Keeping danger color for attention
        light: "#404040", // Darker shade for elements considered 'light'
        background: "#1A1A1A", // Dark background for main theme
        gray: {
            100: "#2E2E2E",
            200: "#373737",
            300: "#404040",
            400: "#4A4A4A",
            500: "#535353",
            600: "#5C5C5C",
            700: "#656565",
        },
        silver: "#a9a9a9", // Adjusted to fit dark theme
        silverTwo: "#525252", // Slightly darker than silver
        kimberly: "#5C5F77", // Adjusted to fit dark theme aesthetics
        spring: "#131313", // Darker, muted version to fit the theme
        pattens: "#32424A", // Adjusted to be darker
        yellow: "#ffb944", // Keeping for contrast
        whiteSmoke: "#2E2E2E", // Dark version of whiteSmoke
        klein: "#FFF9F0", // Adjusted to a gold-like hue, assuming specific use
        water: "#2A3542", // Darkened version of water color
    },
    lineHieghts: {
        body: 1.74,
        heading: 1.41,
    },
    fonts: {
        body: `'Inter', sans-serif`,
        heading: `'Inter', sans-serif`,
        fontAwesome: "Font Awesome 5 Free",
        fontAwesomeBrand: "Font Awesome 5 Brands",
    },
    fontSizes: {
        body: "15px",
        h1: "56px",
        h2: "48px",
        h3: "40px",
        h4: "34px",
        h5: "24px",
        h6: "18px",
    },
    fontWeights: {
        body: 400,
        heading: 700,
    },
    radii: {
        sm: "3px",
        md: "5px",
        lg: "8px",
        round: "50%",
        pill: "500px",
    },
    shadows: {
        default: "0 0 40px rgba(0, 0, 0, 0.2)", // Adjusted for dark theme
        sm: "0 0 20px rgba(0, 0, 0, 0.15)", // Slightly less intense
        md: "0 18px 40px rgba(0, 0, 0, 0.2)", // Maintaining depth
        lg: "0 0 40px 5px rgba(0, 0, 0, 0.2)", // Consistent with theme
        xl: "0 41px 43px rgba(0, 0, 0, 0.1)", // Lower opacity for subtlety
        input: "0 0 0 0.2rem rgb(176 141 87 / 25%)", // Adjusted to match the new gold-like primary color
    },
    transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
    breakpoints: [...breakpoints], // Ensure you define the breakpoints array elsewhere in your code
};

const ctaData = {
    buttons: [
        {
            id: 1,
            path: "/contact-us",
            color: "light",
            icon: "far fa-comment-alt",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            hoverStyle: "three",
            content: "",
        },
        {
            id: 2,
            path: "/contact-us",
            color: "secondary",
            icon: "fas fa-info-circle",
            iconposition: "left",
            icondistance: "8px",
            iconsize: "16px",
            content: "",
        },
    ],
};

export default ctaData;
